/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  TextField,
  Input,
  Stack,
  Grid2,
  Grid,
} from "@mui/material";
import QRCode from "qrcode.react";
import logo from "../assets/logo.png";
import { io } from "socket.io-client";
import { API_URL, SITE_URL, SOCKET_URL } from "../config/constants";
import SomInicio from "../assets/Som tempo.mp3";
import secondSound from "../assets/10seconds.mp3";
import whistleSound from "../assets/apito.mp3";

const RoundTimer = () => {
  const [roundTime, setRoundTime] = useState(300); // 5 minutes in seconds
  const [intervalTime, setIntervalTime] = useState(60); // 1 minute in seconds
  const [currentRound, setCurrentRound] = useState(1);
  const [totalRounds, setTotalRounds] = useState(3);
  const [remainingTime, setRemainingTime] = useState(roundTime);
  const [isRunning, setIsRunning] = useState(false);
  const [isInterval, setIsInterval] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const socketRef = useRef(null); // Reference to the Socket.IO client
  const audioStartRef = useRef(new Audio(SomInicio)); // Som para início das contagens
  const countdownRef = useRef(new Audio(secondSound)); // Som para contagem regressiva de 10 segundos
  const whistleRef = useRef(new Audio(whistleSound)); // Som para os 5 segundos finais do intervalo
  const [currentTime, setCurrentTime] = useState(new Date());
  const countdownPlayedRef = useRef(false); // Rastreamento para evitar tocar múltiplas vezes

  const formatTime = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const formatCurrentTime = (date) => {
    const h = String(date.getHours()).padStart(2, "0");
    const m = String(date.getMinutes()).padStart(2, "0");
    const s = String(date.getSeconds()).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const handleSync = useCallback(() => {
    if (socketRef.current) {
      console.log("Sending sync event to server...", {
        time: remainingTime,
        isRunning,
        currentRound,
        isInterval,
      });
      socketRef.current.emit("sync", {
        time: remainingTime,
        isRunning,
        currentRound,
        isInterval,
      });
    }
  }, [remainingTime, isRunning, currentRound, isInterval]);

  const initializeSocket = (id) => {
    if (socketRef.current) {
      socketRef.current.disconnect(); // Disconnect any existing socket connection
    }

    socketRef.current = io(SOCKET_URL, { query: { sessionId: id } });

    socketRef.current.on("connect", () => {
      console.log("Socket.IO connection established with ID:", id);
      handleSync(); // Sync immediately upon connection
    });

    socketRef.current.on("message", (message) => {
      console.log("Received message from server:", message);
      // Handle messages as needed
    });

    socketRef.current.on("sync", (data) => {
      console.log("Sync data received:", data);
      // Ensure all values are numbers
      setRemainingTime(Number(data.time) || roundTime);
      setIsRunning(Boolean(data.isRunning));
      setCurrentRound(Number(data.currentRound) || currentRound);
      setIsInterval(Boolean(data.isInterval));
    });

    socketRef.current.on("disconnect", () => {
      console.log("Socket.IO connection closed");
    });

    socketRef.current.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const existingSessionId = urlParams.get("session_id");

    console.log("API_URL:", API_URL);

    if (existingSessionId) {
      console.log("Using existing session ID:", existingSessionId);
      setSessionId(existingSessionId);
      initializeSocket(existingSessionId);
    } else {
      console.log("Generating a new session ID...");
      fetch(`${API_URL}/generate_session`)
        .then((response) => response.json())
        .then((data) => {
          const newSessionId = data.session_id;
          console.log("Generated new session ID:", newSessionId);
          setSessionId(newSessionId);
          initializeSocket(newSessionId);
          window.history.replaceState(
            null,
            null,
            `?session_id=${newSessionId}`
          );
        })
        .catch((error) => console.error("Error generating session:", error));
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(); // Clean up on component unmount
      }
    };
  }, [handleSync, initializeSocket]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000);

  //   return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
  // }, []);

  const handleRoundEnd = () => {
    countdownPlayedRef.current = false; // Reseta o som do countdown para o próximo ciclo
    if (isInterval) {
      if (currentRound < totalRounds) {
        startNextRound();
      } else {
        setIsRunning(false); // All rounds finished
      }
    } else {
      startInterval();
    }
  };

  useEffect(() => {
    let timer = null;

    if (isRunning || true) {
      // Timer ou relógio sempre ativo
      timer = setInterval(() => {
        // Atualiza o horário atual
        setCurrentTime(new Date());

        // Atualiza o timer principal se estiver rodando
        if (isRunning && remainingTime > 0) {
          setRemainingTime((prevTime) => {
            if (prevTime === 5) {
              if (isInterval) {
                whistleRef.current.play(); // Toca o apito nos últimos 5 segundos do intervalo
              } else if (!countdownPlayedRef.current) {
                countdownRef.current.play(); // Toca o som de countdown nos últimos 5 segundos do round
                countdownPlayedRef.current = true;
              }
            }
            return prevTime - 1; // Reduz o tempo
          });
        }

        // Lida com o término do timer
        if (remainingTime === 0 && isRunning) {
          handleRoundEnd();
        }
      }, 1000);
    }

    return () => clearInterval(timer); // Limpa o intervalo ao desmontar ou alterar dependências
  }, [isRunning, remainingTime, isInterval, handleRoundEnd]);

  const startNextRound = () => {
    audioStartRef.current.play(); // Toca o som no início do próximo round
    countdownPlayedRef.current = false; // Reseta o som do countdown
    whistleRef.current.pause(); // Pausa o apito
    whistleRef.current.currentTime = 0; // Reseta o tempo do apito
    setIsInterval(false);
    setCurrentRound((prevRound) => prevRound + 1);
    setRemainingTime(roundTime);
    setIsRunning(true);
  };

  const startInterval = () => {
    countdownPlayedRef.current = false; // Reseta o som do countdown
    whistleRef.current.pause(); // Pausa o apito
    whistleRef.current.currentTime = 0; // Reseta o tempo do apito
    setIsInterval(true);
    setRemainingTime(intervalTime);
    setIsRunning(true);
  };

  const startTimer = () => {
    setIsRunning(true); // Inicia o timer
    audioStartRef.current.play(); // Toca o som de início
    socketRef.current?.emit("start"); // Emite evento para o servidor
  };

  const stopTimer = () => {
    setIsRunning(false);
    socketRef.current?.emit("stop");
  };

  const resetTimer = () => {
    setIsRunning(false);
    setRemainingTime(roundTime);
    setCurrentRound(1);
    setIsInterval(false);
    countdownPlayedRef.current = false; // Reseta o som do countdown
    socketRef.current?.emit("reset");
  };

  const handleRoundTimeChange = (event) => {
    const [hours, minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds =
      (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    setRoundTime(totalSeconds);
    setRemainingTime(totalSeconds);
  };

  const handleIntervalTimeChange = (event) => {
    const [hours, minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds =
      (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    setIntervalTime(totalSeconds);
  };

  const handleTotalRoundsChange = (event) => {
    setTotalRounds(Number(event.target.value));
  };

  console.log("isRunning:", isRunning);
  console.log("remainingTime:", remainingTime);
  console.log("currentRound:", currentRound);

  console.log(sessionId);
  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#121212",
        padding: 2,
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {/* Centralizar a Logo */}
        <Grid2
          container
          size={{ xs: 12 }}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            padding: "20px",
            marginRight: 2,
          }}
        >
          <img
            src={logo}
            alt="Company Logo"
            style={{
              width: "270px",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              // marginLeft: "420px",
              // marginRight: "auto",
            }}
          />

          <Grid2 item>
            <Typography variant="h4">
              {formatCurrentTime(new Date())}
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2
          container
          spacing={6}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Altera para coluna no celular
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid2
              container
              sx={{
                width: "202px",
                height: "120px",
                backgroundColor: "#212326",
                borderRadius: "20px",
                border: "1px solid #555",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Round (mm:ss)
              </Typography>
              <TextField
                type="time"
                step="1"
                defaultValue="00:05:00"
                onChange={handleRoundTimeChange}
                sx={{ marginBottom: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1,
                }}
              />
            </Grid2>
          </Grid2>

          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid2
              container
              sx={{
                width: "200px",
                height: "120px",
                backgroundColor: "#212326",
                borderRadius: "20px",
                border: "1px solid #555",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#FFFF",
                  fontWeight: "bold",
                  marginBottom: "8px",
                  textAlign: "center",
                }}
              >
                Intervalo (mm:ss)
              </Typography>
              <TextField
                type="time"
                step="1"
                defaultValue="00:01:00"
                onChange={handleIntervalTimeChange}
                sx={{ marginBottom: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 1,
                }}
              />
            </Grid2>
          </Grid2>

          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid2
              container
              sx={{
                width: "195px",
                height: "120px",
                backgroundColor: "#212326",
                borderRadius: "20px",
                border: "1px solid #555",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Qtd. de Rounds
              </Typography>
              <TextField
                type="number"
                value={totalRounds}
                onChange={handleTotalRoundsChange}
                sx={{ marginBottom: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1,
                }}
              />
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2
          size={{ xs: 12, md: 2 }}
          sx={{
            backgroundColor: "#1e1e1e",
            borderRadius: "16px",
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "6px",
            justifyContent: "center",
            width: { xs: "100%", sm: "100%", md: "730px" },
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: 2,
              marginTop: 2,
              padding: "5px",
              color: "#FFFFFF",
              fontSize: { xs: "40px", md: "20px" },
            }}
          >
            {isInterval ? "Intervalo" : `Round ${currentRound}`}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              marginBottom: 2,
              color: "#FFFFFF",
              fontSize: { xs: "40px", md: "120px" },
            }}
          >
            {formatTime(remainingTime)}
          </Typography>
        </Grid2>

        <Grid2
          container
          spacing={4}
          justifyContent="center"
          sx={{
            mb: 2,
          }}
          size={{ xs: 12 }}
        >
          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={startTimer}
              sx={{
                m: 1,
                backgroundColor: "#7600ED",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Começar
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              color="secondary"
              onClick={stopTimer}
              sx={{
                m: 1,
                backgroundColor: "#F63765",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Parar
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              onClick={resetTimer}
              sx={{
                m: 1,
                backgroundColor: "transparent",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Resetar
            </Button>
          </Grid2>
        </Grid2>
        <Grid2
          xs={12}
          md={6}
          sx={{
            textAlign: { xs: "center", md: "right" },
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          {sessionId && (
            <QRCode
              value={`https://timer.fightgo.com.br/?session_id=${sessionId}`}
              size={90}
              style={{
                margin: "0 10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default RoundTimer;
